import { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    flexRender,
} from "@tanstack/react-table";
import styled from "styled-components";

import { useEmployeesForCustomer } from "../hooks/useEmployeesForCustomer";
import QueryWrapper from "../../../components/application/QueryWrapper";

import StandardTableStyle from "../../../components/application/StandardTableStyle";
import StandardTableContent from "../../../components/application/StandardTableContent";
import { SALARY_CODES } from "./constants";
import { Button, CleanButton } from "../../../components/buttons";
import { T, TB } from "../../../components/texts";

const PayrollTable = ({ customer_id, absences, extras, tasks }) => {
    const { t } = useTranslation();

    const [discards, setDiscards] = useState([]);
    const employees = useEmployeesForCustomer(customer_id);

    const prepareEmployeeRowData = useCallback((tasks, absences, employees, discards) => {
        if (!tasks || !absences) return [];

        const updatedEmployees = employees.reduce((updatedEmployees, employee) => {
            const tasksForEmployee = tasks.filter((task) => task.mechanic_id === employee.id);
            if (tasksForEmployee.length === 0 && !employee.active) return updatedEmployees;

            const absencesForEmployee = absences.filter(
                (absence) => absence.user_id === employee.id
            );

            const totalWorkHours = sumUpTasksDuration(tasksForEmployee);
            const ordinaryWorkHours = sumUpTasksDuration(
                tasksForEmployee?.filter((task) => !task.overtime)
            );
            const workHoursWithOvertime50 = sumUpTasksDuration(
                tasksForEmployee?.filter((task) => task.overtime === 50)
            );
            const workHoursWithOvertime100 = sumUpTasksDuration(
                tasksForEmployee?.filter((task) => task.overtime === 100)
            );
            const numberOfTasksWithDiet95 =
                tasksForEmployee?.filter((task) => task.living_expenses === "9.5t")?.length || 0;
            const numberOfTasksWithDiet12 =
                tasksForEmployee?.filter((task) => task.living_expenses === "12t")?.length || 0;
            const numberOfTasksWithDietOvernight =
                tasksForEmployee?.filter((task) => task.living_expenses === "overnatting")
                    ?.length || 0;
            const numberOfTasksWithExtraCallout =
                tasksForEmployee?.filter((task) => task.extra_callout)?.length || 0;
            const workHoursWithExtraHall = sumUpTasksDuration(
                tasksForEmployee?.filter((task) => task.extra_hall)
            );
            const workHoursWithExtraOut = sumUpTasksDuration(
                tasksForEmployee?.filter((task) => task.extra_out)
            );
            const workHoursWithExtraShift = sumUpTasksDuration(
                tasksForEmployee?.filter((task) => task.extra_shift)
            );
            const numberOfTasksWithExtraOnWatch = extras.filter(
                (extra) => extra.user_id === employee.id && extra.type === "on_watch"
            ).length;
            const totalAbsenceHours = sumUpAbsencesDuration(absencesForEmployee) / 60;

            updatedEmployees.push({
                ...employee,
                totalWorkHours,
                ordinaryWorkHours,
                workHoursWithOvertime50,
                workHoursWithOvertime100,
                numberOfTasksWithDiet95,
                numberOfTasksWithDiet12,
                numberOfTasksWithDietOvernight,
                numberOfTasksWithExtraCallout,
                workHoursWithExtraHall,
                workHoursWithExtraOut,
                workHoursWithExtraShift,
                numberOfTasksWithExtraOnWatch,
                totalAbsenceHours,
            });

            return updatedEmployees;
        }, []);

        const discardsMap = discards.reduce((acc, curr) => {
            acc.set(curr, true);
            return acc;
        }, new Map());
        return updatedEmployees.reduce((acc, curr) => {
            // acc.push({
            //     name: `${curr.first_name} ${curr.last_name}`,
            //     payroll_number: curr.payroll_number,
            //     code: SALARY_CODES.ordinary,
            //     amount: curr.ordinaryWorkHours,
            // });

            const overtime50Id = `${curr.id}-overtime_50`;
            if (curr.workHoursWithOvertime50 > 0) {
                acc.push({
                    id: overtime50Id,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.overtime_50,
                    code_name: t("overtime-50"),
                    amount: curr.workHoursWithOvertime50,
                    discarded: discardsMap.has(overtime50Id),
                });
            }

            const overtime100Id = `${curr.id}-overtime_100`;
            if (curr.workHoursWithOvertime100 > 0) {
                acc.push({
                    id: overtime100Id,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.overtime_100,
                    code_name: t("overtime-100"),
                    amount: curr.workHoursWithOvertime100,
                    discarded: discardsMap.has(overtime100Id),
                });
            }

            const diet95Id = `${curr.id}-diet_9_5`;
            if (curr.numberOfTasksWithDiet95 > 0) {
                acc.push({
                    id: diet95Id,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.diet_9_5,
                    code_name: t("diet-95"),
                    amount: curr.numberOfTasksWithDiet95,
                    discarded: discardsMap.has(diet95Id),
                });
            }

            const diet12Id = `${curr.id}-diet_12`;
            if (curr.numberOfTasksWithDiet12 > 0) {
                acc.push({
                    id: diet12Id,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.diet_12,
                    code_name: t("diet-12"),
                    amount: curr.numberOfTasksWithDiet12,
                    discarded: discardsMap.has(diet12Id),
                });
            }

            // acc.push({
            //     name: `${curr.first_name} ${curr.last_name}`,
            //     payroll_number: curr.payroll_number,
            //     code: SALARY_CODES.overnight,
            //     amount: curr.numberOfTasksWithDietOvernight,
            // });

            const calloutId = `${curr.id}-callout`;
            if (curr.numberOfTasksWithExtraCallout > 0) {
                acc.push({
                    id: calloutId,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.callout,
                    code_name: t("callout"),
                    amount: curr.numberOfTasksWithExtraCallout,
                    discarded: discardsMap.has(calloutId),
                });
            }

            const hallId = `${curr.id}-hall`;
            if (curr.workHoursWithExtraHall > 0) {
                acc.push({
                    id: hallId,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.hall,
                    code_name: t("hall"),
                    amount: curr.workHoursWithExtraHall,
                    discarded: discardsMap.has(hallId),
                });
            }

            const outId = `${curr.id}-out`;
            if (curr.workHoursWithExtraOut > 0) {
                acc.push({
                    id: outId,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.out,
                    code_name: t("out"),
                    amount: curr.workHoursWithExtraOut,
                    discarded: discardsMap.has(outId),
                });
            }

            const shiftId = `${curr.id}-shift`;
            if (curr.workHoursWithExtraShift > 0) {
                acc.push({
                    id: shiftId,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.shift,
                    code_name: t("shift"),
                    amount: curr.workHoursWithExtraShift,
                    discarded: discardsMap.has(shiftId),
                });
            }

            const onwatchId = `${curr.id}-onwatch`;
            if (curr.numberOfTasksWithExtraOnWatch > 0) {
                acc.push({
                    id: onwatchId,
                    name: `${curr.first_name} ${curr.last_name}`,
                    payroll_number: curr.payroll_number,
                    code: SALARY_CODES.onwatch,
                    code_name: t("on_watch"),
                    amount: curr.numberOfTasksWithExtraOnWatch,
                    discarded: discardsMap.has(onwatchId),
                });
            }

            return acc;
        }, []);

        function sumUpTasksDuration(tasks) {
            return tasks?.reduce((acc, task) => acc + task.duration, 0) || 0;
        }

        function sumUpAbsencesDuration(absences) {
            return absences?.reduce((acc, absence) => acc + absence.durationInMinutes, 0) || 0;
        }
    }, []);

    const data = useMemo(
        () =>
            employees?.data
                ? prepareEmployeeRowData(tasks, absences, employees.data, discards)
                : [],
        [employees?.data, tasks, absences, discards]
    );

    const columns = useMemo(
        () => [
            {
                header: t("employee"),
                accessorKey: "name",
            },
            {
                header: t("employee_number"),
                accessorKey: "payroll_number",
            },
            {
                header: t("payroll_code"),
                accessorKey: "code_name",
            },
            {
                header: `${t("payroll_code")} #`,
                accessorKey: "code",
            },
            {
                header: t("count"),
                accessorKey: "amount",
            },
            {
                id: "discard",
                cell: ({ row }) => (
                    <CleanButton
                        type="button"
                        onClick={() => {
                            setDiscards((discards) => {
                                if (discards.includes(row.original.id)) {
                                    return [...discards.filter((id) => id !== row.original.id)];
                                } else {
                                    return [...discards, row.original.id];
                                }
                            });
                        }}
                    >
                        <TB $link>{row.original.discarded ? t("include") : t("exclude")}</TB>
                    </CleanButton>
                ),
            },
        ],
        []
    );

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const headerGroups = table.getHeaderGroups();
    const rowModel = table.getRowModel();

    return (
        <>
            <Button
                disabled={!customer_id}
                onClick={() => {
                    const a = document.createElement("a");
                    a.href = URL.createObjectURL(
                        new Blob(
                            [
                                data?.reduce((acc, curr) => {
                                    if (curr.discarded) return acc;
                                    if (!curr.code) return acc;
                                    const str = `${curr.payroll_number};${curr.code};${curr.amount}`;
                                    if (acc === "") return str;
                                    return `${acc}\n${str}`;
                                }, ""),
                            ],
                            { type: "text/csv" }
                        )
                    );
                    const now = new Date();
                    a.download = `payroll_${now.getFullYear()}_${now.getMonth() + 1}_${now.getDate()}.csv`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }}
            >
                {t("export")}
            </Button>
            <Container>
                <QueryWrapper data={[employees]}>
                    {rowModel.rows.length > 0 ? (
                        <TableStyled>
                            <StandardTableContent
                                headerGroups={headerGroups}
                                rowModel={rowModel}
                                RowComponent={({ row }) => {
                                    if (row.original.discarded) {
                                        return (
                                            <DiscardedRow>
                                                {row.getVisibleCells().map((cell) => {
                                                    return (
                                                        <td key={cell.id}>
                                                            <T>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </T>
                                                        </td>
                                                    );
                                                })}
                                            </DiscardedRow>
                                        );
                                    }

                                    return (
                                        <tr>
                                            {row.getVisibleCells().map((cell) => {
                                                console.log(cell);
                                                return (
                                                    <td key={cell.id}>
                                                        <T>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </T>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                }}
                            />
                        </TableStyled>
                    ) : (
                        <NothingToShow>{t("no_employees_to_show")}</NothingToShow>
                    )}
                </QueryWrapper>
            </Container>
        </>
    );
};

export default PayrollTable;

const Container = styled.article`
    width: 50%;
    margin-top: 3rem;
    min-height: 10rem;
`;

const TableStyled = styled(StandardTableStyle)`
    > thead > tr > th {
        &:not(:first-child) {
            text-align: center;
        }

        &.blue-background {
            background: ${(p) => p.theme.color.primary.xxlight};
        }
        &.border-around {
            border: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }
        &.border-left {
            border-left: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }
        &.border-right {
            border-right: 2px solid ${(p) => p.theme.color.neutral.xlight};
        }

        &:last-child {
            padding-right: 0;
        }
    }

    > tbody > tr > td {
        &:not(:first-child) {
            text-align: center;
        }

        &:nth-child(2) {
            width: 1rem;
        }

        &:last-child {
            min-width: 0;
            padding-right: 0;
        }
    }
`;

const NothingToShow = styled.section`
    min-height: 20rem;
    display: grid;
    place-items: center;

    opacity: 0;
    animation: fadeIn 500ms ease forwards;

    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
`;

const DiscardedRow = styled.tr`
    & > td:not(:last-child) {
        position: relative;
        opacity: 0.5;
    }

    & > td:not(:last-child):after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1.25px;
        background-color: black;
    }
`;
