import styled from "styled-components";
import { QRCodeSVG } from "qrcode.react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "../buttons";

const UnitQrCode = ({ uuid }) => {

  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  return (
          <>
            <QrWrapper ref={contentRef}>
              <QRCodeSVG
                      value={`https://mainflow.no/display/drivers/public/` + uuid}
                      level="H"
                      boostLevel={true}
                      bgColor="rgb(56, 85, 141)"
                      fgColor="#FFFFF9"
                      imageSettings={{
                        src: "/logo192.png",
                        excavate: true,
                        height: 32,
                        width: 32,
                      }}
              />
              <LogoImgWrapper src="/tm_logo.png" alt="Truck og Maskin AS" />
            </QrWrapper>
            <Button onClick={reactToPrintFn}>Skriv Ut</Button>
          </>
  );
};

export default UnitQrCode;

const LogoImgWrapper = styled.img`
  width: 70%;
  margin-top: 2rem;
  border-radius: 0.3rem;
`;

const QrWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
  padding: 2rem 0;
  background-color: rgb(56, 85, 141);
  border-radius: 0.5rem;
  border: 3px solid white;
`;
