export const SALARY_CODES = {
    ordinary: undefined,
    overtime_50: 11150,
    overtime_100: 11200,
    diet_9_5: 32501,
    diet_12: 32502,
    overnight: undefined,
    callout: 800211,
    hall: 800212,
    out: 800209,
    shift: 800213,
    onwatch: 800214,
};
