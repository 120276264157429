import { useEffect, useMemo, Fragment } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useUser } from "./hooks/useUser";
import { useCustomers } from "../customers/hooks/useCustomers";
import { useSaveUser } from "./mutations/useSaveUser";
import QueryWrapper from "../../../components/application/QueryWrapper";

import { device } from "../../../theme";
import UserRoles from "./components/UserRoles";
import ServerSelect from "../../../components/application/ServerSelect";
import SaveOrAbort from "../../../components/application/SaveOrAbort";
import Email from "../../application/components/user/Email";
import MainArea from "../../../components/layout/MainArea";
import { CleanButton } from "../../../components/buttons";
import { H1, T, TB } from "../../../components/texts";
import {
    InputWithLabel,
    InputCheckbox,
    DateTimeInputForm,
    Input,
    ExpandableSection,
} from "../../../components/inputs";
import { Grid, GridBlock } from "../../../components/layout/Grid";
import { FlexGap } from "../../../components/helpers/FlexGap";

const UserEdit = () => {
    const { t } = useTranslation();
    const params = useParams();
    const id = Number(params.id);
    const methods = useForm({ mode: "onChange" });
    const navigate = useNavigate();
    const { search } = useLocation();

    const query = useMemo(() => new URLSearchParams(search), [search]);
    const userTypeFromUrl = query.get("type");
    const isEmployee = methods.watch("employee");

    const user = useUser(id);
    const { mutate: saveUser, isLoading: isSavingUser } = useSaveUser({
        onSuccess: () =>
            navigate(isEmployee ? `/administration/users?type=employee` : `/administration/users`),
    });
    const {
        fields: skillFields,
        append: appendSkill,
        remove: removeSkill,
    } = useFieldArray({ control: methods.control, name: "data.skills" });

    useEffect(() => {
        if (id === 0)
            methods.reset({
                payroll_number: "",
                employee: userTypeFromUrl === "employee" ? true : false,
                first_name: "",
                last_name: "",
                email: null,
                active: true,
                external: "",
                nfc: null,
                roles: [
                    {
                        value: "",
                        label: "",
                    },
                ],
                data: {
                    skills: [{ name: "" }],
                },
            });
    }, [id]);

    useEffect(() => {
        if (user?.data) {
            methods.reset({
                ...user.data,
                data: {
                    ...user.data.data,
                    skills: user.data.data?.skills || [{ name: "" }],
                    signature_media_id: user.data.data?.signature_media_id || null,
                },
            });

            if (user.data.data?.skills?.length) {
                methods.setValue(
                    "data.skills",
                    user.data.data.skills.map((item) => ({ name: item }))
                );
            }
            if (user.data.data?.employed)
                methods.setValue("data.employed", new Date(user.data.data.employed));
        }
    }, [user?.data]);

    const onSubmit = (values) => {
        const post = {
            first_name: values.first_name,
            last_name: values.last_name,
            active: values.active,
            email: values.email,
            customer_id: values.customer_id?.value,
            nfc: values.nfc,
            roles:
                values.roles
                    ?.map((item, index) => ({
                        role_id: item.value,
                        customer_id: values.customers[index].value,
                    }))
                    .filter((item) => item.role_id !== "") || [],
            employee: Boolean(values.employee),
            payroll_number: values.payroll_number || "",
            data: {
                position: values.data?.position || "",
                employed: values.data?.employed || "",
                skills: values.data.skills?.map((item) => item.name) || [],
                address: {
                    streetAddress1: values.data?.address?.streetAddress1 || "",
                    zipCode: values.data?.address?.zipCode || "",
                    city: values.data?.address?.city || "",
                },
                national_id: values.data?.national_id || "",
                salary_account: values.data?.salary_account || "",
                mobile: values.data?.mobile || "",
                contact_person: {
                    name: values.data?.contact_person?.name || "",
                    mobile: values.data?.contact_person?.mobile || "",
                },
                signature_media_id: values.data?.signature_media_id || null,
            },
        };

        if (values.id) post.id = values.id;

        saveUser(post);
    };

    return (
        <MainArea>
            <H1>{id === 0 ? t("create_new_user") : t("change_user")}</H1>

            <QueryWrapper data={id !== 0 ? user : null}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <InputCheckboxStyled name="employee" label={t("employee")} />

                        {/* USER INFO */}
                        <ExpandableSection
                            label={`${t("user_info")} (${t("required")})`}
                            expandedByDefault
                        >
                            <Grid>
                                <ServerSelect
                                    name={"customer_id"}
                                    label={t("customer")}
                                    selectUse={useCustomers}
                                    validate={{ required: t("customer_required") }}
                                />
                            </Grid>

                            <Grid>
                                <InputWithLabel
                                    name="first_name"
                                    label={t("firstname")}
                                    validate={{ required: t("name_required") }}
                                    autoComplete="off"
                                />
                                <InputWithLabel
                                    name="last_name"
                                    label={t("lastname")}
                                    validate={{ required: t("name_required") }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid>
                                <Email
                                    autoComplete="off"
                                    validate={{ required: t("email_required") }}
                                />
                            </Grid>
                            <InputCheckbox name="active" label={t("active")} />
                        </ExpandableSection>

                        {/* ROLES AND ACCESS */}
                        <ExpandableSection label={t("roles_and_access")} expandedByDefault>
                            <UserRoles user_id={id} />
                        </ExpandableSection>

                        {isEmployee && (
                            <>
                                {/* EMPLOYMENT AND SKILLS */}
                                <ExpandableSection
                                    label={t("employment_and_skills")}
                                    expandedByDefault
                                >
                                    <Grid>
                                        <Grid gap={0.5}>
                                            <InputWithLabel
                                                type="number"
                                                name="payroll_number"
                                                label={t("employee_number")}
                                            />
                                            <GridBlock $columns={2}>
                                                <InputWithLabel
                                                    name="data.position"
                                                    label={t("position")}
                                                />
                                            </GridBlock>
                                        </Grid>
                                        <DateInputWrapper>
                                            <DateTimeInputForm
                                                name="data.employed"
                                                label={t("employment_date")}
                                                showDateOnly
                                            />
                                        </DateInputWrapper>
                                    </Grid>

                                    <label id="skills">
                                        <T>{t("skills")}</T>
                                    </label>
                                    <Grid>
                                        <Line style={{ marginTop: "0.2rem" }} />
                                    </Grid>

                                    {skillFields?.map((field, index) => (
                                        <Fragment key={field.id}>
                                            <FlexGap $count={3} gap="1.5rem">
                                                <Input
                                                    {...methods.register(
                                                        `data.skills.${index}.name`
                                                    )}
                                                    aria-labelledby="skills"
                                                />
                                                <DeleteButton
                                                    type="button"
                                                    onClick={() => removeSkill(index)}
                                                >
                                                    <TB $link $underline>
                                                        {t("delete")}
                                                    </TB>
                                                </DeleteButton>
                                            </FlexGap>
                                            <Grid>
                                                <Line />
                                            </Grid>
                                        </Fragment>
                                    ))}

                                    <CleanButton
                                        type="button"
                                        onClick={() => appendSkill({ name: "" })}
                                    >
                                        <TB $link $underline>
                                            {`+ ${t("add_skill")}`}
                                        </TB>
                                    </CleanButton>
                                </ExpandableSection>

                                {/* CONTACT AND PERSONAL INFO */}
                                <ExpandableSection
                                    label={t("contact_info_and_personal_info")}
                                    expandedByDefault
                                >
                                    <Grid>
                                        <GridBlock>
                                            <InputWithLabel
                                                name="data.address.streetAddress1"
                                                label={t("address")}
                                            />

                                            <Grid gap={0.5}>
                                                <InputWithLabel
                                                    name="data.address.zipCode"
                                                    label={t("postal_code")}
                                                />
                                                <GridBlock $columns={2}>
                                                    <InputWithLabel
                                                        name="data.address.city"
                                                        label={t("location")}
                                                    />
                                                </GridBlock>
                                            </Grid>
                                        </GridBlock>

                                        <GridBlock>
                                            <Grid $templateColumns={2}>
                                                <InputWrapper>
                                                    <InputWithLabel
                                                        name="data.national_id"
                                                        label={`${t(
                                                            "personal_identity_number"
                                                        )} (11 ${t("numbers")})`}
                                                    />
                                                </InputWrapper>
                                                <InputWrapper>
                                                    <InputWithLabel
                                                        name="data.salary_account"
                                                        label={t("payroll_account")}
                                                    />
                                                </InputWrapper>
                                                <InputWrapper>
                                                    <InputWithLabel
                                                        name="data.mobile"
                                                        label={t("mobile_number")}
                                                    />
                                                </InputWrapper>
                                            </Grid>
                                        </GridBlock>
                                    </Grid>
                                </ExpandableSection>

                                {/* RELATIVES */}
                                <ExpandableSection label={t("relatives_info")} expandedByDefault>
                                    <Grid $templateColumns={6}>
                                        <GridBlock $columns={2}>
                                            <InputWithLabel
                                                name="data.contact_person.name"
                                                label={t("name")}
                                                validate={{ maxLength: 100 }}
                                            />
                                        </GridBlock>
                                        <InputWrapper>
                                            <InputWithLabel
                                                name="data.contact_person.mobile"
                                                label={t("mobile_number")}
                                                validate={{ maxLength: 30 }}
                                            />
                                        </InputWrapper>
                                    </Grid>
                                </ExpandableSection>
                            </>
                        )}

                        <SaveOrAbort saving={isSavingUser} />
                    </form>
                </FormProvider>
            </QueryWrapper>
        </MainArea>
    );
};

export default UserEdit;

const DateInputWrapper = styled.section`
    max-width: 13rem;
`;

const Line = styled.hr`
    border: none;
    border-bottom: 2px solid ${(p) => p.theme.color.neutral.xlight};
    margin: 1rem 0;
    width: calc(100% + 3.7rem);

    @media only screen and ${device.mobile} {
        width: 100%;
        margin: 1.5rem 0;
    }
`;

const DeleteButton = styled(CleanButton)`
    align-self: center;
`;

const InputWrapper = styled.section`
    overflow: hidden;
`;

const InputCheckboxStyled = styled(InputCheckbox)`
    margin-top: 1rem;
    margin-bottom: 1.5rem;
`;
