import { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
    width: 119px;
    height: 36px;
`;

export const LogoMIcon = ({ className, stroke, ...props }) => {
    const theme = useContext(ThemeContext);
    stroke = stroke ? stroke : theme.color.primary.base;

    return (
        <Svg viewBox="0 0 119 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>

          <path
                d="M0.710938 24.668C1.19531 23.582 1.71875 22.5117 2.28125 21.457C2.84375 20.4102 3.4336 19.418 4.05078 18.4805C4.66016 17.543 5.28516 16.6758 5.92578 15.8789C6.56641 15.0898 7.20313 14.4062 7.83594 13.8281C8.46094 13.2578 9.07422 12.8086 9.67578 12.4805C10.2695 12.1602 10.832 12 11.3633 12C11.582 12 11.7852 12.0391 11.9727 12.1172C12.1602 12.1953 12.3242 12.3008 12.4648 12.4336C12.6055 12.5742 12.7148 12.7383 12.793 12.9258C12.8711 13.1133 12.9102 13.3164 12.9102 13.5352L12.9102 19.2187L18.957 12.457C19.1133 12.3008 19.2852 12.1836 19.4727 12.1055C19.6523 12.0352 19.8398 12 20.0352 12C20.2461 12 20.4453 12.0391 20.6328 12.1172C20.8125 12.1953 20.9727 12.3008 21.1133 12.4336C21.2539 12.5742 21.3672 12.7383 21.4531 12.9258C21.5313 13.1133 21.5703 13.3164 21.5703 13.5352L21.5703 24.668L18.5234 24.668L18.5234 17.4492L12.4531 24.2344C12.2344 24.4531 11.9766 24.5898 11.6797 24.6445C11.375 24.707 11.0781 24.6758 10.7891 24.5508C10.5 24.4336 10.2734 24.2461 10.1094 23.9883C9.9375 23.7383 9.85156 23.4609 9.85156 23.1562L9.85156 15.9141C9.19531 16.5469 8.60156 17.2148 8.07031 17.918C7.53906 18.6211 7.04688 19.3437 6.59375 20.0859C6.13281 20.8359 5.70313 21.5937 5.30469 22.3594C4.89844 23.1328 4.50391 23.9023 4.12109 24.668L0.710938 24.668Z"
                fill="white"
            />
        </Svg>
    );
};
