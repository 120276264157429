import { Fragment } from "react";
import { SortIcon } from "../icons";
import { TB } from "../texts";
import { flexRender } from "@tanstack/react-table";
import StandardTableRow from "./StandardTableRow";

const StandardTableContent = ({ headerGroups, rowModel, renderRowSubComponent, RowComponent }) => (
    <>
        <thead>
            {headerGroups.map((headerGroup) => {
                const { headers, hasCustomFilter } = headerGroup.headers.reduce(
                    (headerDetails, header) => {
                        const canSort = header.column.getCanSort();
                        const isSorted = header.column.getIsSorted();
                        const headerContext = header.getContext();
                        const canFilter = header.column.getCanFilter();
                        const isCustomFilter =
                            headerContext.column.columnDef?.hasOwnProperty("filterElement");

                        headerDetails.headers.push(
                            <th
                                className={header.column.columnDef.className}
                                key={header.id}
                                colSpan={header.colSpan}
                            >
                                <div
                                    className={canSort ? "cursor-pointer select-none" : ""}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <TB>
                                        {flexRender(header.column.columnDef.header, headerContext)}
                                    </TB>

                                    {canSort ? <SortIcon sort={isSorted ?? ""} /> : null}
                                </div>
                            </th>
                        );

                        if (canFilter && isCustomFilter) {
                            headerDetails.hasCustomFilter = true;
                        }

                        return headerDetails;
                    },
                    {
                        headers: [],
                        hasCustomFilter: false,
                    }
                );

                return (
                    <Fragment key={headerGroup.id}>
                        <tr key={`tr-${headerGroup.id}`}>{headers}</tr>
                        {hasCustomFilter ? (
                            <tr key={`filter-${headerGroup.id}`}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={`filter-${header.id}`}
                                        style={{ paddingRight: ".5rem" }}
                                    >
                                        {header.column.getCanFilter() ? (
                                            <Filter {...header.getContext()} />
                                        ) : null}
                                    </th>
                                ))}
                            </tr>
                        ) : null}
                    </Fragment>
                );
            })}
        </thead>

        <tbody>
            {rowModel.rows.map((row) => {
                const isGrouped = row.getIsGrouped();
                if (isGrouped) {
                    // This code has currently been written to work with MyTasks.jsx
                    // Please rewrite to work with any component if necessary.
                    return (
                        <Fragment key={row.id}>
                            <tr>
                                <td
                                    style={{ paddingTop: "2rem", textAlign: "left" }}
                                    colSpan={row.getVisibleCells().length}
                                >
                                    <TB>{row.groupingValue}</TB>
                                </td>
                            </tr>
                            {row.subRows.map((subRow) => (
                                <StandardTableRow
                                    key={subRow.id}
                                    row={subRow}
                                    renderRowSubComponent={renderRowSubComponent}
                                />
                            ))}
                        </Fragment>
                    );
                }

                if (RowComponent) {
                    return <RowComponent key={row.id} row={row} />;
                }

                return (
                    <StandardTableRow
                        key={row.id}
                        row={row}
                        renderRowSubComponent={renderRowSubComponent}
                    />
                );
            })}
        </tbody>
    </>
);

export default StandardTableContent;

function Filter(args) {
    return args.column.columnDef.filterElement?.(args) ?? null;
}
